<template>
    <!--Category-->
    <div class="category">
      <!--    <pre>{{categoryList}}</pre> -->
        <h2 class="title">Category</h2>
        <div class="grid">
            <div class="grid-item" v-if="index < categoryList.length" v-for="index in categoryToShow">
                <router-link  class="card border-0" :to="{name:'Category',params:{categoryId:categoryList[index].id,categoryUrl:categoryList[index].title.split(' ').join('-')}}">
                    <span class="shape"></span>
                    <div class="card-body">
                        <!-- <i class="fi flaticon-makeup"></i> -->
                        <img v-lazy="categoryList[index].menu_image.baseUrl+categoryList[index].menu_image.menuImage" :alt="categoryList[index].title" height="40" width="40">
                        <h2>{{categoryList[index].title}}</h2>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="foot text-center pt-4" v-if="categoryToShow < categoryList.length || categoryList.length > categoryToShow">
            <button type="button" class="btn btn-success shadow-none"  @click="categoryToShow += 14">Explore More</button>
        </div>
        <div class="foot text-center pt-4" v-else>
            <button type="button" class="btn btn-warning shadow-none"  @click="categoryToShow = 14">Explore Less</button>
        </div>
    </div>
    <!--/Category-->
</template>

<script>
export default {
  data(){
    return {
        categoryList:[],
        loading: true,
        errored: false,

        categoryToShow: 14,
    }
  },
 mounted(){

   this.getHeaderMenu();
  },
  methods:{
    getHeaderMenu(){
       this.$http.get("all/header/menu")
       .then(response=>{
          if(response.data.msgFlag==true){
            this.categoryList=response.data.dataInfos;
          }
       })
       .catch(error=>{console.dir(error)})
       .finally();
    },
   
  }
}
</script>

<style>

</style>