import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import plugin from './plugin';

new Vue({
  router,
  store,
  plugin,
  render: function (h) { return h(App) }
}).$mount('#app')
