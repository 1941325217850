<template>
  <!--Products-->
  <div class="products mt-3">
    <div class="row align-items-center mb-3">
      <div class="col-lg-8">
        <h2 class="title pb-0 mb-0">New Arrival</h2>
      </div>
      <!-- <div class="col-lg-4">
                <div class="d-flex justify-content-end">
                    <select class="form-select shadow-none w-auto mr-2" aria-label="Default select example">
                        <option selected>Shorting</option>
                        <option value="1">Low</option>
                        <option value="1">High</option>
                        <option value="1">High to Low</option>
                        <option value="2">Low to High</option>
                        <option value="3">All</option>
                    </select>
                    <select class="form-select shadow-none w-auto" aria-label="Default select example">
                        <option selected>Show Item</option>
                        <option value="1">42</option>
                        <option value="2">84</option>
                        <option value="3">120</option>
                    </select>
                </div>
            </div> -->
    </div>
    <div class="grid">
      <!-- <pre>{{products}}</pre> -->
      <div class="grid-item" v-for="(product, index) in products" :key="index">
        <div class="pro-card card shadow-sm">
          <slot v-if="product.hasDiscount == 1 && isDiscountTimeExpire(product.discountStartAt, product.discountEndAt)">
            <span class="pro-badge offer" data-label="Offer"></span>
          </slot>

          <slot v-if="getProductQuantity(product.quantity_infos) < 1">
            <span class="pro-badge sold-out" data-label="Sold Out"></span>
          </slot>
          <!-- <slot v-else-if="product.date">
                        <span class="pro-badge new" data-label="New"></span>
                    </slot> -->

          <slot v-if="product.product_image != null">
            <router-link :to="{ name: 'ProductDetails', params: { slug: product.targetUrl } }">
              <img v-lazy="product.product_image.baseUrl + product.product_image.productImage" class="d-block w-100"
                :alt="product.name">
            </router-link>
          </slot>
          <div class="card-body">
            <h3>
              <router-link :to="{ name: 'ProductDetails', params: { slug: product.targetUrl } }">
                {{ product.name }}
              </router-link>
            </h3>
          </div>
          <div class="card-footer bg-transparent border-0">
            <div class="content">
              <div class="price">
                <slot v-if="product.quantity_infos != null">
                  <slot
                    v-if="product.hasDiscount == 1 && isDiscountTimeExpire(product.discountStartAt, product.discountEndAt)">
                    <div class="cost-price">৳ {{ product.quantity_infos | sellPrice }}</div>
                    <div class="sell-price ">৳ {{ parseInt(product.discount) }}</div>
                  </slot>
                  <slot v-else>
                    <div class="sell-price">৳ {{ product.quantity_infos | sellPrice }}</div>
                  </slot>
                </slot>
              </div>
              <slot v-if="product.quantity_infos != null">
                <slot v-if="getProductQuantity(product.quantity_infos) > 0">
                  <slot v-if="product.hasSizeVarity == 1 || product.hasColorVarity == 1">
                    <router-link :to="{ name: 'ProductDetails', params: { slug: product.targetUrl } }" class="cart">
                      <i class="mdi mdi-cart-plus"></i>
                      <span>Add to cart</span>
                    </router-link>
                  </slot>
                  <slot v-else>
                    <button type="button" class="cart" v-on:click="addToCart(product.id)">
                      <i class="mdi mdi-cart-plus"></i>
                      <span>Add to cart</span>
                    </button>
                  </slot>
                </slot>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <!--/Products-->
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      products: [],
      loading: true,
      errored: false,
      colorCode: 0,
      sizeCode: 0,
    };
  },
  mounted() {

    this.getNewArriavalProduct();
  },
  methods: {
    getProductDetails(productId, slug) {
      this.$router.push({ name: 'ProductDetails', params: { productId: productId, slug: slug } });

    },
    isDiscountTimeExpire(startAt, endAt) {
      endAt = moment(endAt); //Date format
      startAt = moment(startAt); //Date format
      let today = moment();
      let isAfter = moment(endAt).isAfter(today);
      let isBefore = moment(startAt).isBefore(today);
      if (isAfter && isBefore)
        return true;
      else
        return false;
    },
    addToCart(productId) {
      let cartInfos = this.$store.state.cartInfos;

      let formData = new FormData();

      let flag = false;

      cartInfos.forEach(cartInfo => {
        if (
          cartInfo.productId == productId &&
          cartInfo.isFreeProduct != true &&
          parseInt(cartInfo.colorId) == 0 &&
          parseInt(cartInfo.sizeId) == 0
        ) {
          let quantity = parseFloat(cartInfo.quantity) + 1;
          formData.append("productId[]", productId);
          formData.append("quantity[]", quantity);
          formData.append("isFreeProduct[]", false);
          formData.append("colorCode[]", cartInfo.colorId);
          formData.append("sizeCode[]", cartInfo.sizeId);
          formData.append("addedToCart[]", true);
          flag = true;
        }
        else
          if (cartInfo.isFreeProduct != true) {

            formData.append("productId[]", cartInfo.productId);
            formData.append("quantity[]", cartInfo.quantity);
            formData.append("isFreeProduct[]", cartInfo.isFreeProduct);
            formData.append("colorCode[]", cartInfo.colorId);
            formData.append("sizeCode[]", cartInfo.sizeId);
            formData.append("addedToCart[]", false);
          }
      });
      if (flag != true) {
        formData.append("productId[]", productId);
        formData.append("quantity[]", 1);
        formData.append("isFreeProduct[]", false);
        formData.append("colorCode[]", 0);
        formData.append("sizeCode[]", 0);
        formData.append("addedToCart[]", false);
      }


      // let formData=new FormData();
      //  formData.append("productId",productId);
      //  formData.append("colorCode",this.colorCode);
      //  formData.append("sizeCode",this.sizeCode);
      //  formData.append("quantity",1);

      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };


      this.$http.post("product/add/to/cart", formData, config)
        .then(response => {
          if (response.data.msgFlag == true) {
            // console.dir(response.data);
            this.$store.commit("storeCartInfos", response.data.cartInfos);
            this.$store.commit("storePriceInfos", response.data);
            this.$toaster.success(response.data.msg);
          }
          if (response.data.errMsgFlag == true) {
            this.$store.commit("storeCartInfos", response.data.cartInfos);
            this.$store.commit("storePriceInfos", response.data);
            this.$toaster.warning(response.data.errMsg);
          }
        })
        .catch(error => { console.dir(error) })
        .finally();
    },
    getQuantityLabel(quantityType) {
      let label = "1 PCS";
      if (quantityType != null) {
        label = quantityType.smallQuantity + " " + quantityType.label;
      }
      return label;
    },
    hasProductDiscount(cartRule) {
      if (cartRule.isProductWise == 0 && cartRule.isPriceWise == 0 && cartRule.isProductRequired == 0 && cartRule.isProductDiscount == 1) {
        if (cartRule.rulesFor == 0 || cartRule.rulesFor == 2)
          return true;
        else
          return false;
      }
      else {
        return false;
      }
    },
    getSellPrice(stockInfos) {
      let sellPrice = 0;
      stockInfos.forEach(stockInfo => {
        if (stockInfo.frontend_stock_infos != null) {
          stockInfo.frontend_stock_infos.forEach(stock => {
            sellPrice = parseInt(stock.sellPrice);
          });
        }
      });
      return parseInt(sellPrice);

    },
    getProductQuantity(stockInfos) {
      let quantity = 0;
      stockInfos.forEach(stockInfo => {
        quantity = quantity + stockInfo.quantity;
      });
      return quantity;
    },
    getNewArriavalProduct() {
      this.$http.get("get/new/products")
        .then(response => {
          this.products = response.data;
        })
        .catch(error => { console.dir(error) })
        .finally();
    },
    storeRequestProductInfo(productId, productName) {
      this.$store.commit("clearRequestProductInfo");
      let data = {
        productId: productId,
        productName: productName,
      };

      this.$store.commit("storeRequestProductInfo", data);
    },
  }
}
</script>

<style></style>