<template>
    <!--Sidebar-->
    <div class="sidebar">
        <!-- menu -->
        <div class="menu">
            <!-- <pre>{{$store.state.headerMenus}}</pre> -->
            <ul class="nav flex-column">
                <li class="nav-item">
                    <router-link class="nav-link" :to="{name:'Home'}"><i class="fa fa-home"></i><span>Home</span></router-link>
                </li>
                    
                    <slot v-for="megaMenu in $store.state.headerMenus">
                        <li class="nav-item dropdown" v-if="megaMenu.sub_menu.length>0">
                            <a class="nav-link dropdown-toggle" href="#" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" v-on:click="gotoCategoryPage(megaMenu)">
                                <!-- <i class="fi flaticon-woman"></i> -->
                                <img v-lazy="megaMenu.menu_image.baseUrl+megaMenu.menu_image.menuImage" style="max-height:30px;max-width:30px;" />
                                <span>{{megaMenu.title}}</span>
                            </a>
                            <!-- Dropdown -->
                                <slot v-if="megaMenu.sub_menu.length>0">
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <div class="row">
                                            <slot v-for="subMenu in megaMenu.sub_menu"> 
                                                <div class="col-lg-12">
                                                    <h4 class="dropdown-title">
                                                        <!-- <router-link class="dropdown-item" :to="{name:'Category',params:{categoryId:subMenu.id,categoryUrl:subMenu.title.split(' ').join('-')}}">{{subMenu.title}}</router-link> -->
                                                        <router-link class="dropdown-item" :to="{name:'Category',params:{categoryUrl:subMenu.targetUrl}}">{{subMenu.title}}</router-link>
                                                    </h4>
                                                    <slot v-if="subMenu.sub_sub_menu.length>0">
                                                        <slot v-for="normalMenu in subMenu.sub_sub_menu">
                                                            <!-- <router-link class="dropdown-item" :to="{name:'Category',params:{categoryId:normalMenu.id,categoryUrl:normalMenu.title.split(' ').join('-')}}">{{normalMenu.title}}</router-link> -->
                                                            <router-link class="dropdown-item" :to="{name:'Category',params:{categoryUrl:normalMenu.targetUrl}}">{{normalMenu.title}}</router-link>
                                                        </slot>
                                                    </slot>
                                                </div>
                                            </slot>
                                        </div>
                                    </div>
                                </slot>
                            <!-- /Dropdown -->
                        </li>
                         <li class="nav-item" v-else>
                            <!-- <router-link class="nav-link" :to="{name:'Category',params:{categoryId:megaMenu.id,categoryUrl:megaMenu.title.split(' ').join('-')}}">
                                 <img v-lazy="megaMenu.menu_image.baseUrl+megaMenu.menu_image.menuImage" style="max-height:30px;max-width:30px;" />
                                <span>{{megaMenu.title}}</span>
                            </router-link> -->
                            <router-link class="nav-link" :to="{name:'Category',params:{categoryUrl:megaMenu.targetUrl}}">
                                 <img v-lazy="megaMenu.menu_image.baseUrl+megaMenu.menu_image.menuImage" style="max-height:30px;max-width:30px;" />
                                <span>{{megaMenu.title}}</span>
                            </router-link>
                        </li>  
                    </slot>
                <li class="nav-item">
                    <!-- <router-link class="nav-link" to="/preorder"><i class="fi flaticon-sent"></i><span>Pre Order</span></router-link> -->
                    <router-link class="nav-link" to="/preorder"><i class="mdi mdi-cart-outline"></i><span>Pre Order</span></router-link>
                </li>
            </ul>
            <!--Contact-->
            <div class="contact">
                <div class="phone">
                    <img v-lazy="hotLine" alt="Makeup World BD">
                    <h4>Hot Line</h4>
                    <slot v-if="$store.state.appInfos!=null">
                        <h2 >{{$store.state.appInfos.phone}}</h2>
                    </slot>
                </div>
                <div class="phone">
                    <img v-lazy="bkash" alt="Makeup World BD">
                    <h4>Bkash </h4>
                    <slot v-if="$store.state.appInfos!=null">
                        <h2 >{{$store.state.appInfos.bkashNo}}</h2>
                    </slot>
                </div>
            </div>
            <!--/Contact-->
            <!-- <pre>{{menus}}</pre> -->
            <!-- <pre>{{category}}</pre> -->
        </div>
        
        <!-- /menu -->
    </div>
    <!--/Sidebar-->
</template>

<script>
import { Dropdown } from 'bootstrap';
export default {
  data(){
    return {
         hotLine:require('../assets/img/icon/telephone.png'),
         bkash:require('../assets/img/icon/bkash.png'),
    }
  },
 mounted(){
   this.getHeaderMenu();
  },
  methods:{
    gotoCategoryPage(dataInfo){
        // this.$route.push({name:'Category',params:{categoryId:dataInfo.id,categoryUrl:dataInfo.split(' ').join('-')}})
        this.$route.push({name:'Category',params:{categoryUrl:dataInfo.targetUrl}})
    },
    getHeaderMenu(){
       this.$http.get("header/menu")
       .then(response=>{
          if(response.data.msgFlag==true)
            this.$store.commit("storeHeaderMenus",response.data.dataInfos);
       })
       .catch(error=>{console.dir(error)})
       .finally();
    },
   
  }
}
</script>
