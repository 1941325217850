<template>
    <!--Banner-->
    <div class="banner">
        <!-- <pre>{{sliders}}</pre> -->
        <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <a class="carousel-item" :class="{ active: item_index==0 }" v-for="(slide, item_index) in sliders" :key="item_index" :href="slide.targetUrl">
                    <img :src="slide.baseUrl+slide.sliderUrl" class="d-block w-100" :alt="slide.title">
                </a>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </a>
            </div>
    </div>
    <!--/Banner-->
</template>

<script>
export default {
    data() {
        return {
            sliders: [],
        }
    },
    mounted(){
        this.getSliders();
    },
    methods:{
         getSliders(){
           this.$http.get("sliders")
             .then(response=>{
                if(response.data.msgFlag==true)
                    this.sliders=response.data.dataInfos;
             })
             .catch(error=>{console.dir(error)})
             .finally();
        },
    }
}
</script>

<style>

</style>