<template>
  <div id="app">

    <div class="y-blue-admin" id="changeLayout" :class="($store.state.layout) ? 'change':''">
      <Sidebar/>
      <Header/>
      <!-- main -->
      <main class="main">
          <div class="container-wrapper">
            <router-view/>
          </div>
      </main>
      <!-- /main -->
      <Footer/>
      <Cart/>
      <PreOrderCart/>
      <Login/>
      <ProductDetails/>
    </div>
  </div>
</template>

<script>
import Sidebar from './components/Sidebar'
import Header from './components/Header'
import Footer from './components/Footer'
import Login from './views/elements/Login'
import ProductDetails from './views/elements/ProductDetails'
import Cart from './views/elements/Cart.vue'
import PreOrderCart from './views/elements/PreOrderCart.vue'
export default {
  components: {
    Sidebar,
    Header,
    Footer,
    Cart,
    Login,
    ProductDetails,
    PreOrderCart
  }
}
</script>

<style>

</style>

