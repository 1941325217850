<template>
  <!--Products-->
  <div class="products mt-3">
    <div class="row align-items-center mb-3">
      <div class="col-lg-8">
        <h2 class="title pb-0 mb-0">Current Offer</h2>
      </div>

    </div>
    <div class="grid">
      <!-- <pre>{{products}}</pre> -->
      <div class="grid-item" v-for="(product, index) in products" :key="index">
        <div class="pro-card card shadow-sm">
          <slot v-if="product.hasDiscount == 1 && isDiscountTimeExpire(product.discountStartAt, product.discountEndAt)">
            <span class="pro-badge offer" data-label="Offer"></span>
          </slot>

          <slot v-if="getProductQuantity(product.quantity_infos) < 1">
            <span class="pro-badge sold-out" data-label="Sold Out"></span>
          </slot>
          <!-- <slot v-else-if="product.date">
                        <span class="pro-badge new" data-label="New"></span>
                    </slot> -->

          <slot v-if="product.product_image != null">
            <router-link :to="{ name: 'ProductDetails', params: { slug: product.targetUrl } }">
              <img v-lazy="product.product_image.baseUrl + product.product_image.productImage" class="d-block w-100"
                :alt="product.name">
            </router-link>
          </slot>
          <div class="card-body">
            <h3>
              <router-link :to="{ name: 'ProductDetails', params: { slug: product.targetUrl } }">
                {{ product.name }}
              </router-link>
            </h3>
          </div>
          <div class="card-footer bg-transparent border-0">
            <div class="content">
              <div class="price">
                <slot v-if="product.quantity_infos != null">
                  <slot
                    v-if="product.hasDiscount == 1 && isDiscountTimeExpire(product.discountStartAt, product.discountEndAt)">
                    <div class="cost-price">৳ {{ product.quantity_infos | sellPrice }}</div>
                    <div class="sell-price ">৳ {{ parseInt(product.discount) }}</div>
                  </slot>
                  <slot v-else>
                    <div class="sell-price">৳ {{ product.quantity_infos | sellPrice }}</div>
                  </slot>
                </slot>
              </div>
              <slot v-if="product.quantity_infos != null">
                <slot v-if="getProductQuantity(product.quantity_infos) > 0">
                  <slot v-if="product.hasSizeVarity == 1 || product.hasColorVarity == 1">
                    <router-link :to="{ name: 'ProductDetails', params: { slug: product.targetUrl } }" class="cart">
                      <i class="mdi mdi-cart-plus"></i>
                      <span>Add to cart</span>
                    </router-link>
                  </slot>
                  <slot v-else>
                    <button type="button" class="cart" v-on:click="addToCart(product.id)">
                      <i class="mdi mdi-cart-plus"></i>
                      <span>Add to cart</span>
                    </button>
                  </slot>
                </slot>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <!--/Products-->
</template>
<script>
import moment from "moment";
import $ from 'jquery';
export default {
  data() {
    return {
      metaProductInfo: {},
      metaProductImage: null,
      products: [],
      loading: true,
      errored: false,
      colorCode: 0,
      sizeCode: 0,
    };
  },
  // metaInfo() {
  //   return {
  //     title: `MWBD | ${this.metaProductInfo.name} `,
  //     meta: [
  //       { name: 'author', content: "Makeup World BD" },
  //       { name: 'keywords', content: this.metaProductInfo.metaKey },
  //       { name: 'description', content: this.metaProductInfo.metaDetails },
  //       { name: 'title', content: this.metaProductInfo.metaTtile },
  //       { property: 'og:title', content: this.metaProductInfo.metaTtile },
  //       { property: 'og:site_name', content: 'Makeup World BD' },
  //       { property: 'og:description', content: this.metaProductInfo.metaDetails },
  //       { property: 'og:type', content: 'products' },
  //       { property: 'og:url', content: location.href },
  //       { property: 'og:image', content: this.metaProductImage }
  //     ]
  //   }
  // },
  beforeMount() {
    this.getCurrentOfferProducts();
  },
  methods: {
    setMetaInfo() {
      $("title").text(this.metaProductInfo.metaTtile);
      $('.title').attr('content', this.metaProductInfo.metaTtile);
      $('.description').attr('content', this.metaProductInfo.metaDescription);
      $('.keyword').attr('content', this.metaProductInfo.metaKeywords);
      $('.image').attr('content', this.metaProductImage);
      $('.url').attr('content', location.href);

      // Set Twitter meta tags
      $('meta[name="twitter:card"]').attr('content', 'summary_large_image');
      $('meta[name="twitter:title"]').attr('content', this.metaProductInfo.metaTtile);
      $('meta[name="twitter:description"]').attr('content', this.metaProductInfo.metaDescription);
      $('meta[name="twitter:image"]').attr('content', this.metaProductImage);


      $('.author').attr('content', this.metaProductInfo.metaAuthor);
      $('meta[name="keywords"]').attr('content', this.metaProductInfo.metaKey);
      $('meta[name="description"]').attr('content', this.metaProductInfo.metaDetails);
      $('meta[property="og:title"]').attr('content', this.metaProductInfo.metaTtile);
      $('meta[property="og:site_name"]').attr('content', 'Makeup World BD');
      $('meta[property="og:description"]').attr('content', this.metaProductInfo.metaDetails);
      $('meta[property="og:type"]').attr('content', 'products');
      $('meta[property="og:url"]').attr('content', location.href);
      $('meta[property="og:image"]').attr('content', this.metaProductImage);
    },

    getProductDetails(productId, productName) {
      this.$router.push({ name: 'ProductDetails', params: { productId: productId, slug: productName.split(' ').join('-') } });

    },
    addToCart(productId) {

      let cartInfos = this.$store.state.cartInfos;

      let formData = new FormData();

      let flag = false;

      cartInfos.forEach(cartInfo => {
        if (
          cartInfo.productId == productId &&
          cartInfo.isFreeProduct != true &&
          parseInt(cartInfo.colorId) == 0 &&
          parseInt(cartInfo.sizeId) == 0
        ) {
          let quantity = parseFloat(cartInfo.quantity) + 1;
          formData.append("productId[]", productId);
          formData.append("quantity[]", quantity);
          formData.append("isFreeProduct[]", false);
          formData.append("colorCode[]", cartInfo.colorId);
          formData.append("sizeCode[]", cartInfo.sizeId);
          formData.append("addedToCart[]", true);
          flag = true;
        }
        else
          if (cartInfo.isFreeProduct != true) {

            formData.append("productId[]", cartInfo.productId);
            formData.append("quantity[]", cartInfo.quantity);
            formData.append("isFreeProduct[]", cartInfo.isFreeProduct);
            formData.append("colorCode[]", cartInfo.colorId);
            formData.append("sizeCode[]", cartInfo.sizeId);
            formData.append("addedToCart[]", false);
          }
      });
      if (flag != true) {
        formData.append("productId[]", productId);
        formData.append("quantity[]", 1);
        formData.append("isFreeProduct[]", false);
        formData.append("colorCode[]", 0);
        formData.append("sizeCode[]", 0);
        formData.append("addedToCart[]", true);
      }


      // let formData=new FormData();
      //  formData.append("productId",productId);
      //  formData.append("colorCode",this.colorCode);
      //  formData.append("sizeCode",this.sizeCode);
      //  formData.append("quantity",1);

      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };


      this.$http.post("product/add/to/cart", formData, config)
        .then(response => {
          if (response.data.msgFlag == true) {
            // console.dir(response.data);
            this.$store.commit("storeCartInfos", response.data.cartInfos);
            this.$store.commit("storePriceInfos", response.data);
            this.$toaster.success(response.data.msg);
          }
          if (response.data.errMsgFlag == true) {
            this.$store.commit("storeCartInfos", response.data.cartInfos);
            this.$store.commit("storePriceInfos", response.data);
            this.$toaster.warning(response.data.errMsg);
          }
        })
        .catch(error => { console.dir(error) })
        .finally();
    },
    isDiscountTimeExpire(startAt, endAt) {
      endAt = moment(endAt); //Date format
      startAt = moment(startAt); //Date format
      let today = moment();
      let isAfter = moment(endAt).isAfter(today);
      let isBefore = moment(startAt).isBefore(today);
      if (isAfter && isBefore)
        return true;
      else
        return false;
    },
    getQuantityLabel(quantityType) {
      let label = "1 PCS";
      if (quantityType != null) {
        label = quantityType.smallQuantity + " " + quantityType.label;
      }
      return label;
    },

    getProductQuantity(stockInfos) {
      let quantity = 0;
      stockInfos.forEach(stockInfo => {
        quantity = quantity + stockInfo.quantity;
      });
      return quantity;
    },
    getCurrentOfferProducts() {
      this.$http.get("discount/products")
        .then(response => {
          this.products = response.data;
          if (this.products.length > 0) {
            this.metaProductInfo = this.products[0];
            if (this.metaProductInfo.product_image != null)
              this.metaProductImage = this.metaProductInfo.product_image.baseUrl + this.metaProductInfo.product_image.productImage

            let schemaTag = this.$options.filters.schemaFilter(this.metaProductInfo);//this.schemaFilter(this.metaProductInfo);

            // $("#schema").text(JSON.stringify(schemaTag));
            // $('link[rel=canonical]').attr('href', location.href);
            // this.setMetaInfo();
          }
        })
        .catch(error => { console.dir(error) })
        .finally();
    },
    storeRequestProductInfo(productId, productName) {
      this.$store.commit("clearRequestProductInfo");
      let data = {
        productId: productId,
        productName: productName,
      };

      this.$store.commit("storeRequestProductInfo", data);
    },
    schemaFilter(productInfo) {
      productInfo['@context'] = "http://schema.org";
      productInfo['@type'] = "Product";
      return productInfo
    }

  }
}
</script>

<style></style>