<template>
    <!--Footer-->
    <footer class="footer">
        <div class="row">
             <slot v-if="$store.state.appInfos!=null">
                <div class="col-lg-3">
                   
                    <h2 class="h1">
                      <span>{{$store.state.appInfos.name}}</span>
                    </h2>
                    <p>{{$store.state.appInfos.address}}</p>
                    <p><strong>Email:</strong>{{$store.state.appInfos.email}}</p>
                    <p><strong>Phone:</strong>{{$store.state.appInfos.phone}}</p> <p>
                    <strong>Bkash:</strong>{{$store.state.appInfos.bkashNo}}</p>
                    <p>
                        <router-link :to="{name:'sitemapHtml'}">Sitemap</router-link>
                    </p>

                </div>
            </slot>
            <slot v-for="(megaMenu,megaIndex) in $store.state.footerMenus">
                <div class="col-lg-3" :key="megaIndex">
                    <div class="pro-menu">
                        <h4>{{megaMenu.title}}</h4>
                        <ul class="nav flex-column">
                            <slot v-for="(subMenu,subIndex) in megaMenu.sub_menu">
                                <li class="nav-item" :key="subIndex">
                                    <router-link class="nav-link" :to="{name:'Content',params:{contentUrl:subMenu.title.split(' ').join('-'),contentId:subMenu.id}}">{{subMenu.title}}</router-link>
                                </li>
                            </slot>
                        </ul>
                    </div>
                </div>
            </slot>
            <div class="col-lg-3">
                <div class="pro-menu">
                    <h4>Connect With us</h4>
                    <ul class="nav flex-row">
                        <li class="nav-item">
                            <a :href="($store.state.appInfos!=null) ? $store.state.appInfos.facebook:''" target="_blank" class="nav-link">
                                <img v-lazy="facebook" alt="Makeup World BD">
                            </a>
                        </li>
                        <li class="nav-item">
                            <a :href="($store.state.appInfos!=null) ? $store.state.appInfos.instagram:''" target="_blank" class="nav-link">
                                <img v-lazy="instagram"   alt="Makeup World BD">
                            </a>
                        </li>
                        <li class="nav-item">
                            <a :href="($store.state.appInfos!=null) ? $store.state.appInfos.twitter:''" target="_blank" class="nav-link">
                                <img  v-lazy="twitter"  alt="Makeup World BD">
                            </a>
                        </li>
                        <li class="nav-item">
                            <a :href="($store.state.appInfos!=null) ? $store.state.appInfos.youtube:''" target="_blank" class="nav-link">
                                <img v-lazy="youtube"   alt="Makeup World BD">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-10 text-right pt-3">
                <p class="pay-text">Payment Methods: <img v-lazy="payment" alt="Makeup World BD"></p>
            </div>
        </div>
    </footer>
    <!--/Footer-->
        
</template>

<script>
export default {
  data(){
    return {
        payment:require('../assets/img/payment.png'),
        facebook:require('../assets/img/icon/facebook.png'),
        twitter:require('../assets/img/icon/twitter.png'),
        instagram:require('../assets/img/icon/instagram.png'),
        youtube:require('../assets/img/icon/youtube.png'),
    }
  },
 mounted(){
   this.getHeaderMenu();
  },
  methods:{
    getHeaderMenu(){
       this.$http.get("footer/menu")
       .then(response=>{
          if(response.data.msgFlag==true)
            this.$store.commit("storeFooterMenus",response.data.dataInfos);
       })
       .catch(error=>{console.dir(error)})
       .finally();
    },
   
  }
}
</script>

<style>

</style>