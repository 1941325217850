import Vue from 'vue';
import axios from 'axios';
// import VueAxios from 'vue-axios';
// Vue.use(VueAxios, axios);
import moment from 'moment';
import numeral from "numeral";
import Toaster from 'v-toaster';

import 'v-toaster/dist/v-toaster.css';


import VueMeta from 'vue-meta'
Vue.use(VueMeta)

Vue.use(Toaster, {
  timeout: 5000
});

Vue.config.productionTip = false;

import Paginate from 'vuejs-paginate';
Vue.component('paginate', Paginate);

import VueLazyload from 'vue-lazyload';

Vue.use(VueLazyload);

// or with options
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: 'dist/error.png',
  loading: 'dist/loading.gif',
  attempt: 1
});


const base = axios.create({
  baseURL: "https://makeupworldbd.com/makeupshop/api/"
  //  baseURL: "http://localhost/makeupshop/public/api/"
  //  baseURL: "http://localhost:8000/api/"
});

Vue.prototype.$http = base;

Vue.filter("sellPrice", function (stockInfos) {
  let sellPrice = 0;
  stockInfos.forEach(stockInfo => {
    sellPrice = parseInt(stockInfo.sellRate);
  });
  return parseInt(sellPrice);
});
Vue.filter("formatNumberWithOutDecimal", function (value) {
  return numeral(value).format("0");
});
Vue.filter("formatNumberWithDecimal", function (value) {
  return numeral(value).format(0.0);
});

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});
Vue.filter("formatDateTime", function (value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY hh:mm A");
  }
});

Vue.filter("formatTime", function (value) {
  if (value) {
    return moment(String(value)).format("hh:mm A");
  }
});

Vue.filter("formatYear", function (value) {
  if (value) {
    return moment(String(value)).format("YYYY");
  }
});
Vue.filter("imageApis", function (productImages) {

  let thumbs = [];
  let normal_size = [];

  productImages.forEach(productImage => {
    thumbs.push({
      'id': productImage.id,
      'url': productImage.baseUrl + productImage.productImage
    });
    normal_size.push({
      'id': productImage.id,
      'url': productImage.baseUrl + productImage.productImage
    });
  });

  return {
    'thumbs': thumbs,
    'normal_size': normal_size
  };

});
Vue.filter("schemaFilter", function (productInfo) {

  let schemaInfo = {};
  schemaInfo['@context'] = "http://schema.org";
  schemaInfo['@type'] = "Product";

  // Essential Fields
  schemaInfo.name = productInfo.name;
  schemaInfo.description = productInfo.metaDetails;
  // schemaInfo.brand = {
  //     '@type': "Brand",
  //     name: "Your Brand Name"
  // };


    schemaInfo.image = productInfo.product_image.baseUrl + productInfo.product_image.productImage;

  schemaInfo.url = window.location.href;

  schemaInfo.productID = productInfo.id; // Optional, if applicable

  // Additional Fields
  // schemaInfo.manufacturer = {
  //     '@type': "Organization",
  //     name: "Manufacturer Name"
  // };

  schemaInfo.model = productInfo.model;
  schemaInfo.sku = productInfo.code;

  // schemaInfo.weight = {
  //     '@type': "QuantitativeValue",
  //     value: "10",
  //     unitCode: "KG"
  // };

  // schemaInfo.color = "Product Color";
  // schemaInfo.material = "Material Used";

  // Offer Details
  schemaInfo.offers = {
    '@type': "Offer",
    price: sellPrice(productInfo),//this.$options.filters.sellPrice(productInfo.quantity_infos),
    priceCurrency: "BDT",
    availability: "https://schema.org/InStock",
    // priceValidUntil: "2024-12-31", // Optional, if there's a limited-time offer
    seller: {
      '@type': "Organization",
      name: "Makeup World BD",
      url: "https://makeupworldbd.com",
      logo: "https://makeupworldbd.com/favicon.ico"
    }
  };

  // Reviews and Ratings
  schemaInfo.aggregateRating = {
    '@type': "AggregateRating",
    ratingValue: Math.floor(Math.random() * (5 - 3)) + 3,
    reviewCount: productInfo.id,
  };

  // Include more fields as per your product's characteristics

  return schemaInfo;
});


function sellPrice(productInfo)
{
  let sellPrice=0;
  productInfo.quantity_infos.forEach(stockInfo => {
    sellPrice = parseInt(stockInfo.sellRate);
  });

 if(productInfo.hasDiscount == 1 && isDiscountTimeExpire(productInfo.discountStartAt, productInfo.discountEndAt))
 {
   return parseFloat(productInfo.discount);
 }

  return parseFloat(sellPrice);
}

function isDiscountTimeExpire(startAt, endAt) {
  endAt = moment(endAt); //Date format
  startAt = moment(startAt); //Date format
  let today = moment();
  let isAfter = moment(endAt).isAfter(today);
  let isBefore = moment(startAt).isBefore(today);
  if (isAfter && isBefore)
    return true;
  else
    return false;
}

import '@/assets/sass/main.scss'
export default {}