<template>
  <div class="home">
    <Slider/>
    <Shipping/>
    <Category/>
    <NewArrival/>
    <CurrentOffer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Slider from './elements/Slider'
import Shipping from './elements/Shipping'
import Category from './elements/Category'
import NewArrival from './elements/NewArrival'
import CurrentOffer from './elements/CurrentOffer'

import $ from 'jquery';

export default {
  name: 'Home',
  components: {
    Slider,
    Shipping,
    Category,
    NewArrival,
    CurrentOffer
  },

  metaInfo() {
    return {
      title:this.title,
      meta: [
        { name: 'author', content: "Makeup World BD" },
        { name: 'keywords', content: this.keywords},
        { name: 'description', content: this.description },
        { name: 'title', content: this.title },
        { property: 'og:title', content: this.title },
        { property: 'og:site_name', content: 'Makeup World BD' },
        { property: 'og:description', content: this.description},
        { property: 'og:type', content: 'customer service' },
        { property: 'og:url', content: location.href },
        { property: 'og:image', content: this.image }
      ]
    }
  },
  data(){
    return{
      title:"Makeup World BD",
      description:"Discover a world of beauty at Makeup World BD! Elevate your glam game with our exquisite range of high-quality cosmetics and skincare products. Unleash your creativity and enhance your natural beauty with our trendsetting makeup essentials. Shop with confidence, as Makeup World BD brings you the latest in beauty innovation. Your journey to flawless radiance begins here – explore the best in makeup at Makeup World BD!",
      keywords:"Makeup World BD, Cosmetics Bangladesh, Beauty Products Online, Skincare Essentials, Trendsetting Makeup, High-Quality Cosmetics, Beauty Innovation, Glamour Collection, Radiant Beauty, Makeup Essentials, Beauty Trends, Makeup Trends, Cosmetic Store Bangladesh, Online Beauty Shopping, Flawless Radiance, Beauty and Wellness, Creative Makeup, Makeup Inspiration, Beauty Accessories, Confidence in Beauty",
      // image:this.$store.state.appInfos.logo??'',
      image:require('../assets/logo.png'),
      url:location.href,
    }
  },
  mounted(){
   this.setSeoInfo();
  },
  methods:{
    
    setSeoInfo(){
      // console.dir(JSON.stringify(this.generateSchema()));
        $("#schema").text(JSON.stringify(this.generateSchema()));
        $('link[rel=canonical]').attr('href', this.url);
      //  this.setMetaInfo();
    },

    setMetaInfo() {
      $("title").text(this.title);
      $('.title').attr('content', this.title);
      $('.description').attr('content', this.description);
      $('.keyword').attr('content', this.keywords);
      $('.image').attr('content', this.image);
      $('.url').attr('content', this.url);

      // Set Twitter meta tags
      $('meta[name="twitter:card"]').attr('content', 'summary_large_image');
      $('meta[name="twitter:title"]').attr('content', this.title);
      $('meta[name="twitter:description"]').attr('content', this.description);
      $('meta[name="twitter:image"]').attr('content', this.image);


      $('.author').attr('content', "makeup world bd");
      $('meta[name="keywords"]').attr('content', this.keywords);
      $('meta[name="description"]').attr('content', this.description);
      $('meta[property="og:title"]').attr('content', this.title);
      $('meta[property="og:site_name"]').attr('content', 'Makeup World BD');
      $('meta[property="og:description"]').attr('content', this.description);
      $('meta[property="og:type"]').attr('content', 'customer service');
      $('meta[property="og:url"]').attr('content', this.url);
      $('meta[property="og:image"]').attr('content',  this.image);
    },
    generateSchema() {
      let schemaInfo = {};

      schemaInfo["@context"]= "https://schema.org",
      schemaInfo["@type"]= "Organization",
      schemaInfo.name= this.title,
      schemaInfo.url= this.url,
      schemaInfo.logo= this.logo,

      schemaInfo.contactPoint={
        '@type': 'ContactPoint',
          'telephone': '+8801979955001',
          'contactType': 'customer service'
      }
      return schemaInfo;
    }
  },
}
</script>
