import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

let cartInfos = window.localStorage.getItem("cartInfos");
let preOrderCartInfos = window.localStorage.getItem("preOrderCartInfos");
let priceInfos = window.localStorage.getItem("priceInfos");
let preOrderPriceInfos = window.localStorage.getItem("preOrderPriceInfos");
let customerInfo = window.localStorage.getItem("customerInfo");

export default new Vuex.Store({
  state: {
    productId:null,
    apiToken:null,
    rememberToken:null,
    customerPhone:null,
    orderHistory:null,
    orderDetails:null,
    customerInfo:customerInfo ? JSON.parse(customerInfo) : null,
    productInfos:[],
    cartInfos: cartInfos ? JSON.parse(cartInfos) : [],
    priceInfos: priceInfos ? JSON.parse(priceInfos) : [],
    preOrderCartInfos: preOrderCartInfos ? JSON.parse(preOrderCartInfos) : [],
    preOrderPriceInfos: preOrderPriceInfos ? JSON.parse(preOrderPriceInfos) : [],
    fullCart:false,
  	footerMenus:[],
  	layout:false,
  	headerMenus:[],
  	appInfos:null,
    onlinePaymentUrl: "https://makeupworldbd.com/makeupshop/order/",
    // onlinePaymentUrl: "https://sodaykori.com/makeupshop/order/",
    // onlinePaymentUrl: "http://localhost/makeupshop/public/order/",
  },
  mutations: {
    storeOrderHistory(state,datas){state.orderHistory=datas;},
    storeOrderDetails(state,datas){state.orderDetails=datas;},
    storeCustomerInfo(state,datas){
        state.customerInfo=datas;
        if(datas!=null){
            state.customerPhone=datas.phone;
            state.rememberToken=datas.remember;
            state.apiToken=datas.token;
        }
        else{
            state.customerPhone=null;
            state.rememberToken=null;
            state.apiToken=null;
        }
        window.localStorage.removeItem("customerInfo");
        window.localStorage.setItem("customerInfo", JSON.stringify(state.customerInfo));
    },
    storeProductDetails(state,datas){
            state.productInfos=datas;
            state.productId=datas.id;
           
    },
    removeProductFromCart(state, index) {
        state.cartInfos.splice(index, 1);
        this.commit("storeCartInfos",state.cartInfos);
      //  this.commit("storePriceInfos",state.cartInfos);
    },
    removePreOrderProductFromCart(state, index) {
        state.preOrderCartInfos.splice(index, 1);
        this.commit("storePreOrderCartInfos",state.preOrderCartInfos);
      //  this.commit("storePriceInfos",state.cartInfos);
    },
    storePriceInfos(state,datas){
        state.priceInfos=datas;
        window.localStorage.removeItem("priceInfos");
        window.localStorage.setItem("priceInfos", JSON.stringify(state.priceInfos));
    },
    storeCartInfos(state,datas){
        state.cartInfos=datas;
        window.localStorage.removeItem("cartInfos");
        window.localStorage.setItem("cartInfos", JSON.stringify(state.cartInfos));
    },
     storePreOrderPriceInfos(state,datas){
        state.preOrderPriceInfos=datas;
        window.localStorage.removeItem("preOrderPriceInfos");
        window.localStorage.setItem("preOrderPriceInfos", JSON.stringify(state.preOrderPriceInfos));
    },
    storePreOrderCartInfos(state,datas){
        state.preOrderCartInfos=datas;
        window.localStorage.removeItem("preOrderCartInfos");
        window.localStorage.setItem("preOrderCartInfos", JSON.stringify(state.preOrderCartInfos));
    },
    changeCartView(state){state.fullCart=!state.fullCart},
    minimizeCartView(state){state.fullCart=false;},
  	storeFooterMenus(state,datas){ state.footerMenus=datas; },
  	changeLayout(state,flag){ state.layout=flag; },
  	storeAppInfos(state,datas){ state.appInfos=datas; },
  	storeHeaderMenus(state,datas){ state.headerMenus=datas; }
  },


})
