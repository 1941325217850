<template>
  <div>
      <!--add to cart-->
        <div class="add-to-cart" :style="{display:(isCartVisiable()) ? 'none':''}">
            <button type="button" class="cart-btn" @click="$store.commit('changeCartView')">
                <span>
                    <i class="mdi mdi-cart-outline"></i>
                    <sup class="badge badge-light">{{($store.state.preOrderPriceInfos!=null) ? $store.state.preOrderPriceInfos.totalItem:0}}</sup>
                </span>
            </button>
        </div>
        <!--/add to cart-->
        <!--Card List-->
        <div class="cart-list" :class="{show: $store.state.fullCart }" :style="{display:(isCartVisiable()) ? 'none':''}">
            <div class="cart-header">
                <h5>Pre Order Shopping Cart</h5>
                <button type="button" class="btn shadow-none" @click="$store.commit('changeCartView')">
                    <span class="mdi mdi-minus-box-outline"></span>
                </button>
            </div>
            <div class="cart-body">
                <slot v-if="$store.state.preOrderCartInfos!=null">
                    <ul class="nav flex-column">
                        <!-- <pre>{{$store.state.preOrderCartInfos}}</pre> -->
                        <li v-for="(cartInfo, index) in $store.state.preOrderCartInfos" :key="index">
                            <img :src="cartInfo.productImage" :alt="cartInfo.name">
                            <div class="content">
                                <h5>{{cartInfo.name}}</h5>
                                <slot v-if="cartInfo.hasSizeVarity==1">
                                  <h6>Size: {{cartInfo.size}}</h6>
                                </slot>
                                <slot v-if="cartInfo.hasColorVarity==1">
                                  <h6>Color: {{cartInfo.color}}</h6>
                                </slot>
                                <span>{{cartInfo.quantity}} X ৳ {{cartInfo.rate}}</span>
                                <div class="qty">
                                    <button type="button" v-on:click="productQuantityDecrease(index)">
                                        <i class="mdi mdi-minus"></i>
                                    </button>
                                    <input type="number" min="1" :value="cartInfo.quantity" readonly>
                                    <button type="button" v-on:click="productQuantityIncrease(index)">
                                        <i class="mdi mdi-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="price">
                                <slot v-if="cartInfo.discountFlag==true">
                                    <div class="cost-price">৳ {{(cartInfo.price*cartInfo.quantity)}}</div>
                                    <div class="sell-price">৳ {{(cartInfo.price*cartInfo.quantity)-cartInfo.discount}}</div>
                                </slot>
                                <slot v-else>
                                    <div class="sell-price">৳ {{cartInfo.price}}</div>
                                </slot>
                            </div>
                            <slot v-if="cartInfo.isFreeProduct!=true">
                                <button class="btn remove shadow-none" @click="removeProduct(index)"><i class="mdi mdi-trash-can-outline"></i></button>
                            </slot>
                        </li>
                    </ul>
                </slot>
                <!-- <slot v-else>
                    <div class="row col-lg-12 text-center text-warning h1">
                        <i class="mdi mdi-cart-outline "></i>
                    </div>
                </slot> -->
            </div>
            <div class="cart-footer">
                <slot v-if="$store.state.preOrderPriceInfos!=null">
                    <!-- <div class="voucher">
                        <span>Voucher Code</span>
                        <input type="text" placeholder="type your code">
                    </div> -->
                    <h3 class="total-price">Total: ৳ {{($store.state.preOrderPriceInfos.totalPrice-($store.state.preOrderPriceInfos.productDiscount+$store.state.preOrderPriceInfos.invoiceDiscount+$store.state.preOrderPriceInfos.promoDiscount))}}</h3>
                    <div class="check-btn">
                        <!-- <router-link to="/products" class="btn btn-primary shadow-none mr-2">Update your cart</router-link> -->
                        <button class="btn btn-success shadow-none" :disabled="$store.state.preOrderPriceInfos.totalItem<1" @click="gotoCheckOutPage()">Place your order</button>
                    </div>
                </slot>
            </div>
        </div>
        <!--/Card List-->
  </div>
</template>

<script>
export default {
    data () {
        return {
           
        }
    },
    methods: {
      isCartVisiable(){
        if(this.$route.name=='PreOrder' || this.$route.name=='signinfor.preOrderCheckout' || this.$route.name=='PreOrderCheckout')
          return false;
        else
          return true;
      },
        productQuantityDecrease(index){
           
            let productId=this.$store.state.preOrderCartInfos[index]['productId'];

            let colorId=this.$store.state.preOrderCartInfos[index]['colorId'];

            let sizeId=this.$store.state.preOrderCartInfos[index]['sizeId'];

            let preOrderCartInfos = this.$store.state.preOrderCartInfos;

            let formData=new FormData();

            let flag=false;
      
            preOrderCartInfos.forEach(cartInfo => {
            if(
              cartInfo.productId ==productId &&
              cartInfo.isFreeProduct != true && 
              parseInt(cartInfo.colorId)==colorId && 
              parseInt(cartInfo.sizeId)==sizeId
            ) {
                 let quantity = parseInt(cartInfo.quantity);

                if(quantity>1){
                   quantity=quantity-1;
                }

              formData.append("productId[]", productId);
              formData.append("quantity[]", quantity);
              formData.append("isFreeProduct[]", false);
              formData.append("colorCode[]", cartInfo.colorId);
              formData.append("sizeCode[]", cartInfo.sizeId);
              formData.append("addedToCart[]", true);
              flag = true;
            } 
            else 
            if( cartInfo.isFreeProduct != true ){

              formData.append("productId[]", cartInfo.productId);
              formData.append("quantity[]", cartInfo.quantity);
              formData.append("isFreeProduct[]", cartInfo.isFreeProduct);
              formData.append("colorCode[]", cartInfo.colorId);
              formData.append("sizeCode[]", cartInfo.sizeId);
              formData.append("addedToCart[]", false);
            }
          });
          


            // let formData=new FormData();
            //  formData.append("productId",productId);
            //  formData.append("colorCode",this.colorCode);
            //  formData.append("sizeCode",this.sizeCode);
            //  formData.append("quantity",1);

            let config = {
                   headers: {
                      'Content-Type': 'multipart/form-data'
                  }   
              }; 
        

           this.$http.post("pre/order/product/add/to/cart",formData,config)
            .then(response=>{
              if(response.data.msgFlag==true){
                // console.dir(response.data);
                  this.$store.commit("storePreOrderCartInfos",response.data.cartInfos);
                  this.$store.commit("storePreOrderPriceInfos",response.data);
                   this.$toaster.success(response.data.msg);
              }
              if(response.data.errMsgFlag==true){
                    this.$store.commit("storePreOrderCartInfos",response.data.cartInfos);
                    this.$store.commit("storePreOrderPriceInfos",response.data);
                    this.$toaster.warning(response.data.errMsg);
              }
            })
            .catch(error=>{console.dir(error)})
            .finally();
        },
        productQuantityIncrease(index){
           
            let productId=this.$store.state.preOrderCartInfos[index]['productId'];

            let colorId=this.$store.state.preOrderCartInfos[index]['colorId'];

            let sizeId=this.$store.state.preOrderCartInfos[index]['sizeId'];

            let preOrderCartInfos = this.$store.state.preOrderCartInfos;

            let formData=new FormData();

            let flag=false;
      
            preOrderCartInfos.forEach(cartInfo => {
            if(
              cartInfo.productId ==productId &&
              cartInfo.isFreeProduct != true && 
              parseInt(cartInfo.colorId)==colorId && 
              parseInt(cartInfo.sizeId)==sizeId
            ) {
              let quantity = parseFloat(cartInfo.quantity) + 1;
              formData.append("productId[]", productId);
              formData.append("quantity[]", quantity);
              formData.append("isFreeProduct[]", false);
              formData.append("colorCode[]", cartInfo.colorId);
              formData.append("sizeCode[]", cartInfo.sizeId);
              formData.append("addedToCart[]", true);
              flag = true;
            } 
            else 
            if( cartInfo.isFreeProduct != true ){

              formData.append("productId[]", cartInfo.productId);
              formData.append("quantity[]", cartInfo.quantity);
              formData.append("isFreeProduct[]", cartInfo.isFreeProduct);
              formData.append("colorCode[]", cartInfo.colorId);
              formData.append("sizeCode[]", cartInfo.sizeId);
              formData.append("addedToCart[]", false);
            }
          });
          


            // let formData=new FormData();
            //  formData.append("productId",productId);
            //  formData.append("colorCode",this.colorCode);
            //  formData.append("sizeCode",this.sizeCode);
            //  formData.append("quantity",1);

            let config = {
                   headers: {
                      'Content-Type': 'multipart/form-data'
                  }   
              }; 
        

           this.$http.post("pre/order/product/add/to/cart",formData,config)
            .then(response=>{
              if(response.data.msgFlag==true){
                // console.dir(response.data);
                  this.$store.commit("storePreOrderCartInfos",response.data.cartInfos);
                  this.$store.commit("storePreOrderPriceInfos",response.data);
                  this.$toaster.success(response.data.msg);
              }
              if(response.data.errMsgFlag==true){
                  this.$store.commit("storePreOrderCartInfos",response.data.cartInfos);
                  this.$store.commit("storePreOrderPriceInfos",response.data);
                  this.$toaster.warning(response.data.errMsg);
              }
            })
            .catch(error=>{console.dir(error)})
            .finally();
        },
        removeProduct(index){
            
            this.$store.commit('removePreOrderProductFromCart',index);

            let preOrderCartInfos = this.$store.state.preOrderCartInfos;

            let formData=new FormData();

            let flag=false;
      
            preOrderCartInfos.forEach(cartInfo => {
            if(cartInfo.isFreeProduct != true ) {
              let quantity = parseFloat(cartInfo.quantity) + 1;
              formData.append("productId[]", cartInfo.productId);
              formData.append("quantity[]", cartInfo.quantity);
              formData.append("isFreeProduct[]", false);
              formData.append("colorCode[]", cartInfo.colorId);
              formData.append("sizeCode[]", cartInfo.sizeId);
              formData.append("addedToCart[]", false);
              flag = true;
            }
          });

            let config = {
                   headers: {
                      'Content-Type': 'multipart/form-data'
                  }   
              }; 
        

           this.$http.post("pre/order/product/add/to/cart",formData,config)
            .then(response=>{
              // if(response.data.msgFlag==true){
              //   // console.dir(response.data);
              //    this.$store.commit("storePreOrderCartInfos",response.data.preOrderCartInfos);
              //    this.$store.commit("storePreOrderPriceInfos",response.data);
              //     // this.$toaster.success('A Product Removed From Cart Successfully.');
              // }
              // if(response.data.errMsgFlag==true){
              //     this.$store.commit("storePreOrderCartInfos",response.data.preOrderCartInfos);
              //     this.$store.commit("storePreOrderPriceInfos",response.data);
              //     // this.$toaster.success('A Product Removed From Cart Successfully.');
              // }
              this.$store.commit("storePreOrderCartInfos",response.data.cartInfos);
              this.$store.commit("storePreOrderPriceInfos",response.data);
               this.$toaster.success('A Product Removed From Cart Successfully.');
            })
            .catch(error=>{console.dir(error)})
            .finally();
        },
        gotoCheckOutPage(){
            this.$router.push({name:'PreOrderCheckout'});
        }
    }
}
</script>

<style>

</style>