<template>
    <!--Shipping Info-->
    <div class="shpping-info">
        <div class="row">
            <div class="col-lg-3">
                <div class="card border-0">
                    <div class="card-body">
                        <div class="ship-card">
                            <div class="icon"><i class="fas fa-shipping-fast"></i></div>
                            <div class="content">
                                <h2>Delivery</h2>
                                <span>Within 1-3 Days</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="card border-0">
                    <div class="card-body">
                        <div class="ship-card">
                            <div class="icon"><i class="fas fa-piggy-bank"></i></div>
                            <div class="content">
                                <h2>Shop With Saving</h2>
                                <span>Save Upto 50% </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="card border-0">
                    <div class="card-body">
                        <div class="ship-card">
                            <div class="icon"><i class="fas fa-shield-alt"></i></div>
                            <div class="content">
                                <h2>Secure Payment</h2>
                                <span>100% Secure</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="card border-0">
                    <div class="card-body">
                        <div class="ship-card">
                            <div class="icon"><i class="fas fa-question-circle"></i></div>
                            <div class="content">
                                <h2>Help Center</h2>
                                <span>24/7 Support</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--/Shipping Info-->
</template>

<script>
export default {

}
</script>

<style>

</style>