import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/sitemap.xml',
  //   name: 'sitempaXml',
  //   component: function(){
  //     return import('../views/sitemapXml.vue')
  //   }
  // },
  {
    path: '/sitemap.html',
    name: 'sitemapHtml',
    component: function(){
      return import('../views/sitemapHtml.vue')
    }
  },
  {
    path: '/:contentId/content/:contentUrl',
    name: 'Content',
    component: function () {
      return import('../views/Content.vue')
    }
  },
  // {
  //   path: '/category/:categoryId/:categoryUrl',
  //   name: 'Category',
  //   component: function () {
  //     return import('../views/CategoryProduct.vue')
  //   }
  // },
  {
    path: '/category/:categoryUrl',
    name: 'Category',
    component: function () {
      return import('../views/CategoryProduct.vue')
    }
  },
  {
    path: '/search/:searchKey',
    name: 'Search',
    component: function () {
      return import('../views/SearchProduct.vue')
    }
  },
  // {
  //   path: '/product/:productId/:slug',
  //   name: 'ProductDetails',
  //   component: function () {
  //     return import('../views/ProductDetails.vue')
  //   }
  // },
  {
    path: '/product/:slug',
    name: 'ProductDetails',
    component: function () {
      return import('../views/ProductDetails.vue')
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: function () {
      return import('../views/Dashboard.vue')
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: function () {
      return import('../views/elements/ProfileEdit.vue')
    }
  },
  {
    path: '/:orderId/invoice',
    name: 'Invoice',
    component: function () {
      return import('../views/elements/Invoice.vue')
    }
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: function () {
      return import('../views/Checkout.vue')
    }
  }, 
  {
    path: '/pre/order/checkout',
    name: 'PreOrderCheckout',
    component: function () {
      return import('../views/PreOrderCheckout.vue')
    }
  },
  {
    path: '/preorder',
    name: 'PreOrder',
    component: function () {
      return import('../views/PreOrder.vue')
    }
  },
  {
    path: '/product-request',
    name: 'ProductRequest',
    component: function () {
      return import('../views/ProductRequest.vue')
    }
  },
  {
    path: '/registration',
    name: 'Registration',
    component: function () {
      return import('../views/elements/Registration.vue')
    }
  },
  {
    path: '/signin/to/checkout',
    name: 'signinfor.checkout',
    component: function () {
      return import('../views/elements/SigninCheckout.vue')
    }
  },
   {
    path: '/signin/to/pre/order/checkout',
    name: 'signinfor.preOrderCheckout',
    component: function () {
      return import('../views/elements/SigninPreOrderCheckout.vue')
    }
  },
   {
    path: '/forgot/password',
    name: 'customer.password.reset',
    component: function () {
      return import('../views/elements/PasswordReset.vue')
    }
  },
  {
    path: '/order/:orderId/success',
    name: 'Order.Success',
    component: function () {
      return import('../views/elements/OrderSuccess.vue')
    }
  },
  {
    path: '*',
    component: () => import('../views/NotFound.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'is-active',
  linkExactActiveClass: "exact-active",
  routes,
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
})

export default router
