<template>
    <!-- Login Modal -->
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered login">
            <div class="modal-content">
                <div class="modal-body">
                    <span class="shape"></span>
                    <button type="button" class="btn-close float-right" data-bs-dismiss="modal" aria-label="Close"></button>
                    <form action="#" class="p-5" @submit.prevent>
                        <div class="form-group text-center">
                            <h2>Login</h2>
                        </div>
                        <div class="row" v-if="errMsgFlag">
                            <div class="col-lg-12">
                                <div class="alert alert-danger h5 bg-danger text-light p-2 alert-dismissible fade show" role="alert">{{errMsg}}</div>
                            </div>
                        </div>
                        <div class="row" v-if="msgFlag">
                            <div class="col-lg-12">
                                    <div class="alert alert-success h5 bg-success text-light p-2  alert-dismissible fade show" role="alert">{{msg}}</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="Email">Email Or Phone</label>
                            <input type="text" class="form-control shadow-none" placeholder="Email or Phone No" v-model="userName">
                        </div>
                        <div class="form-group">
                            <label for="Password">Password</label>
                            <input type="password" class="form-control shadow-none" placeholder="Password" v-model="password">
                        </div>
                        <div class="form-group">
                            <div class="row no-gutters">
                                <div class="col-lg-6">
                                    <!-- <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                        Remember me
                                        </label>
                                    </div> -->
                                </div>
                                <div class="col-lg-6 text-right">
                                    <router-link :to="{name:'customer.password.reset'}" >Forget Password?</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="form-group text-center">
                            <button type="submit" class="btn btn-primary shadow-none" v-on:click="signinFunction()">Login</button>
                        </div>
                        <div class="form-group text-center">
                            <p class="mb-0">Don’t have account? <router-link to="/registration" >Create New</router-link></p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- /Login Modal -->
</template>

<script>
export default {
    data() {
        return {
            userName:null,
            password:null,
            msgFlag:false,
            errMsgFlag:false,
            msg:null,
            errMsg:null,
        }
    },
     beforeMount(){
      this.getCustomerInfo();
    },
    methods:{
      getCustomerInfo(){
        if(this.$store.state.customerInfo!=null){
            let formData=new FormData();
            let customerInfo=this.$store.state.customerInfo;
            formData.append("phone",customerInfo.phone);
            formData.append("token",customerInfo.token);
            formData.append("remember",customerInfo.remember);
            this.$http.get("customer/info")
                  .then(response=>{
                    if(response.data.msgFlag==true){
                      this.$store.commit("storeCustomerInfo",response.data.customerInfo);
                       this.$router.push({name:'Dashboard'});
                    }
                    else{
                      // this.$router.push({name:'Home'});
                    }
                  })
                  .catch(error=>{
                    console.dir(error);
                    // this.$router.push({name:'Home'});
                  })
                  .finally();
        }
      },
        signinFunction(){
            if(this.formValidation()){
                let formData=new FormData();
                formData.append("userName",this.userName);
                formData.append("password",this.password);
                this.$http.post("customer/signin",formData)
                 .then(response=>{
                    if(response.data.msgFlag==true){
                        this.$toaster.success(response.data.msg);
                        this.$store.commit('storeCustomerInfo',response.data.customerInfo);
                        this.userName=null;
                        this.password=null;
                        this.$router.push({name:'Dashboard'});
                    }
                    else{
                        this.errMsgFlag=response.data.errMsgFlag;
                        this.errMsg=response.data.errMsg;
                        this.$toaster.warning(this.errMsg);
                    }
                 })
                 .catch(error=>{console.dir(error)})
                 .finally();
            }
        },
        formSubmitPrevent(e){
             e.preventDefault();
        },
        formValidation(){
            if(this.userName!=null && this.password!=null){
                return true;
            }
            else{
                if(this.userName==null){
                    this.errMsg="Please Enter Your Phone Number First.";
                    this.$toaster.warning(this.errMsg);
                }
                if(this.password==null){
                    this.errMsg="Please Enter Password.";
                    this.$toaster.warning(this.errMsg);
                }
                this.errMsgFlag=true;
                return false;
            }
        }
    }
}
</script>

<style>

</style>