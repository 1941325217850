<template>
  <!--Header-->
  <header class="header">
      <div class="header-content d-flex justify-content-between align-items-center">
          <div class="flex-item">
              <div class="logo">
                  <button type="button" class="btn btn-light shadow-none" v-on:click="layoutChange()">
                      <span class="mdi mdi-menu"></span>
                  </button>
                  <!-- <img :src="logo" alt=""> -->
                  <router-link to="/">
                      <h2 class="mb-0 h1">
                        <slot v-if="$store.state.appInfos!=null">
                            <span >{{this.$store.state.appInfos.name}}</span>
                          </slot>
                          <slot v-else>
                            <span >Makeup World BD</span>
                        </slot>
                      </h2>
                  </router-link>
              </div>
          </div>
          <div class="flex-item">
              <!-- search box -->
              <div class="search-box">
                  <form action="#">
                      <i class="mdi mdi-magnify"></i>
                      <input type="search" class="form-control shadow-none" placeholder="Search for products..." v-on:input="searchProduct" v-model="searchKey">
                  </form>
              </div>
              <!-- /search box -->
          </div>
          <div class="mobile-menu flex-item navbar navbar-expand-lg p-0">
            <ul class="w-100 nav align-items-center justify-content-center">
               <!-- <li class="nav-item dropdown">
                    <button type="button" class="nav-link bg-transparent border-0 px-5" v-on:click="$store.commit('changeCartView')">
                        
                        <span class="notification">
                            <i class="mdi mdi-cart-outline"></i>
                            <span class="badge badge-danger rounded-pill">{{($store.state.priceInfos!=null) ? $store.state.priceInfos.totalItem:0}}</span>
                        </span>
                      
                    </button>
                </li> -->
               <slot v-if="$store.state.customerInfo!=null">
                    <li class="nav-item dropdown user-item">
                      <div class="user-info">
                          <a href="#" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                            <slot v-if="$store.state.customerInfo.avatar!=null">
                              <img src="https://glenfarrow.co.uk/wp-content/uploads/User-icon-413x413.png" height="35" width="35">
                            </slot>
                            <slot v-else>
                               <span class="mdi mdi-account-outline"></span>
                            </slot>
                              <div class="ui-title">
                                  {{$store.state.customerInfo.name}}
                              </div>
                          </a>
                          <!--Dropdown-->
                          <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                              <li><router-link class="dropdown-item" :to="{name:'Dashboard'}"><i class="mdi mdi-view-dashboard-outline pr-1"></i>Dashboard</router-link></li>
                              <li><router-link class="dropdown-item" :to="{name:'Profile'}"><i class="mdi mdi-cog-outline pr-1"></i>Profile</router-link></li>
                              <li><a class="dropdown-item" href="#" v-on:click="logoutFunction()"><i class="mdi mdi-logout pr-1"></i>Logout</a></li>
                          </ul>
                          <!--/Dropdown-->
                      </div>
                  </li>
              </slot>
              <slot v-else>
                 <li class="nav-item dropdown user-item">
                      <div class="user-info">
                          <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                              <span class="mdi mdi-account-outline"></span>
                              <div class="ui-title">
                                  Login | Registration
                              </div>
                          </a>
                      </div>
                  </li> 
              </slot>
            </ul>
          </div>
      </div>
  </header>
  <!--/Header-->
</template>

<script>
export default {
  data(){
    return {
      // logo: require('../../../assets/frontend/img/logo.png'),
      isActive: true,
      isShow:false,
      headerMenus:null,
      searchKey:null,
      appInfos:[],
      searchKey:null,
    }
  },
 mounted(){
    this.getAppInfos();
    this.getCustomerInfo();
  },
  methods:{
    logoutFunction(){
       window.localStorage.removeItem("customerInfo");
       this.$store.commit("storeCustomerInfo",null);
       this.$router.push({name:'Home'});
       this.$toaster.success("Successfully Logged Out.");
    },
    searchProduct(){
      this.$router.push({name:'Search',params:{searchKey:this.searchKey.split(' ').join('-')}});
    },
    layoutChange(){
        let flag=this.$store.state.layout;
        if(flag)
          flag=false;
        else
          flag=true;
        this.$store.commit("changeLayout",flag);
    },
    getAppInfos(){
      this.$http.get("appinfo")
       .then(response=>{
        if(response.data.msgFlag==true)
          this.$store.commit("storeAppInfos",response.data.dataInfos);
       })
       .catch(error=>{console.dir(error)})
       .finally();
    },

    getCustomerInfo(){
        let customerInfo=this.$store.state.customerInfo;
        if(customerInfo==null){
          customerInfo={
            phone:null,
            remember:null,
            token:null,
          };
        }
        let formData=new FormData();
        formData.append("phone",customerInfo.phone);
        formData.append("token",customerInfo.phone);
        formData.append("remember",customerInfo.remember);

        this.$http.post("auth/check",formData)
         .then(response=>{
          if(response.data.msgFlag)
            this.$store.commit("storeCustomerInfo",response.data.customerInfo);
          else
             this.$store.commit("storeCustomerInfo",null);
         })
         .catch(error=>{})
         .finally();
    }
  }
}
</script>
