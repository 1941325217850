<template>
    <!-- Product Details Modal -->
    <div class="modal fade" id="productdetails" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="productdetailsLabel" aria-hidden="true">
       
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content product-details">
                <div class="modal-body">
                    <button type="button" class="btn close shadow-none" data-bs-dismiss="modal" aria-label="Close">
                        <span class="mdi mdi-minus-box-outline"></span>
                    </button>
                    <div class="row">
                        <div class="col-lg-5">
                            <!-- Product Viewer -->
                            <div class="product_viewr">
                                <div id="productViewr" class="carousel slide carousel-fade" data-bs-ride="carousel">
                                    <div class="carousel-inner">
                                        <slot v-if="$store.state.productInfos!=null">
                                            <slot v-for="(dataInfo,index) in $store.state.productInfos.product_images">
                                                <div class="carousel-item " :class="{ active: index==0 }" :key="index">
                                                    <img :src="dataInfo.baseUrl+dataInfo.productImage" class="d-block w-100" :alt="dataInfo.altName">
                                                </div>
                                            </slot>
                                        </slot>
                                    </div>
                                    <div class="carousel-tab-controller">
                                        <a class="tab-prev" href="#productViewr" role="button" data-bs-slide="prev">
                                            <span class="mdi mdi-chevron-left mdi-hc-2x"></span>
                                            <span class="sr-only">Previous</span>
                                        </a>
                                            <div class="carousel-indicators">
                                                <slot v-if="$store.state.productInfos!=null">
                                                    <slot v-for="(dataInfo,index) in $store.state.productInfos.product_images">
                                                        <img data-bs-target="#productViewr" :src="dataInfo.baseUrl+dataInfo.productImage" :alt="dataInfo.altName" :data-bs-slide-to="index"  :class="{ active: index==0 }" :key="index">
                                                    </slot>
                                                </slot>
                                            </div>
                                        <a class="tab-next" href="#productViewr" role="button" data-bs-slide="next">
                                            <span class="mdi mdi-chevron-right mdi-hc-2x"></span>
                                            <span class="sr-only">Next</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!-- /Product Viewer -->
                        </div>
                        <div class="col-lg-7">
                            <div class="content">
                              <!--   <pre>{{$store.state.productInfos}}</pre> -->
                                <h2>{{$store.state.productInfos.name}}</h2>
                                <h6>Reference: #MWBD-{{ $store.state.productInfos.id}}</h6>
                                <div class="price">
                                    <slot v-if="$store.state.productInfos.quantity_infos!=null">    
                                    <slot v-if="$store.state.productInfos.hasDiscount==1 && isDiscountTimeExpire($store.state.productInfos.discountStartAt,$store.state.productInfos.discountEndAt)">
                                      <div class="cost-price">৳ {{$store.state.productInfos.quantity_infos | sellPrice}}</div> 
                                      <div class="sell-price ">৳ {{parseInt($store.state.productInfos.discount)}}</div>
                                    </slot>
                                    <slot v-else>
                                      <div class="sell-price">৳ {{$store.state.productInfos.quantity_infos | sellPrice}}</div>
                                    </slot>
                                </slot>
                                </div>
                                <div class="qty">
                                    <button type="button" v-on:click="productQuantityChange(-1)">
                                        <i class="mdi mdi-minus"></i>
                                    </button>
                                    <input type="number" min="1" value="1" v-model="quantity">
                                    <button type="button" v-on:click="productQuantityChange(1)">
                                        <i class="mdi mdi-plus"></i>
                                    </button>
                                </div>
                            <slot v-if="$store.state.productInfos!=null ">
                                <slot v-if="$store.state.productInfos.hasSizeVarity==1 || $store.state.productInfos.hasColorVarity==1">
                                    <div class="d-flex align-items-center mb-3">
                                        <slot v-if="$store.state.productInfos.hasColorVarity==1">
                                            <div class="color">
                                                 <div class="form-check form-check-inline pl-0">
                                                    <strong>Color: </strong>
                                                </div>
                                                 <div class="form-check form-check-inline pl-0">
                                                    <select class="form-control shadow-none w-auto" v-model="colorCode" v-on:change="getSizeAndColorWisePrice()">
                                                        <slot v-for="colorInfo in  uniqueColors($store.state.productInfos.quantity_infos)">
                                                          <slot v-if="colorInfo.color_info!=null">
                                                            <option :value="colorInfo.color_info.id">{{colorInfo.color_info.color}}</option>
                                                          </slot>
                                                        </slot>
                                                    </select>
                                                </div>
                                            </div>
                                        </slot>
                                        <slot v-if="$store.state.productInfos.hasSizeVarity==1">
                                            <div class="size pl-3">
                                                 <div class="form-check form-check-inline pl-0">
                                                    <strong>Size: </strong>
                                                </div>
                                                 <div class="form-check form-check-inline pl-0">
                                                    <!-- <pre>{{uniqueSizes($store.state.productInfos.quantity_infos)}}</pre> -->
                                                    <select class="form-control shadow-none w-auto" v-model="sizeCode" v-on:change="getSizeAndColorWisePrice()">
                                                        <slot v-for="sizeInfo in  uniqueSizes($store.state.productInfos.quantity_infos)">
                                                            <slot v-if="sizeInfo.size_info!=null">
                                                              <option :value="sizeInfo.size_info.id">{{sizeInfo.size_info.size}}</option>
                                                            </slot>
                                                          </slot>
                                                    </select>
                                                </div>
                                            </div>
                                        </slot>
                                    </div>
                                </slot>
                            </slot>
                             <slot v-if="$store.state.productInfos.quantity_infos!=null">
                                 <slot v-if="quantity<=getProductQuantity($store.state.productInfos.quantity_infos)">
                                    <button type="button" class="cart"  v-on:click="addToCartSpecificProduct($store.state.productInfos.id,$store.state.productInfos.hasSizeVarity,$store.state.productInfos.hasColorVarity)">
                                        <i class="mdi mdi-cart-plus"></i>
                                        <span>Buy Now</span>
                                    </button>
                                </slot>
                            </slot>
                                <p class="des" v-html="$store.state.productInfos.shortDetails"></p>
                                <p class="des" v-html="$store.state.productInfos.details"></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer bg-light border-0">
                    <div class="row w-100">
                        <div class="col-lg-3">
                            <!--Contact-->
                            <div class="contact">
                                <div class="phone">
                                    <img v-lazy="hotLine" alt="Makeup World BD">
                                    <h4 >Hot Line</h4>
                                    <slot v-if="$store.state.appInfos!=null">
                                        <h2 >{{$store.state.appInfos.phone}}</h2>
                                    </slot>
                                </div>
                                <div class="phone">
                                    <img v-lazy="bkash" alt="Makeup World BD">
                                    <h4>Bkash </h4>
                                    <slot v-if="$store.state.appInfos!=null">
                                        <h2 >{{$store.state.appInfos.bkashNo}}</h2>
                                    </slot>
                                </div>
                            </div>
                            <!--/Contact-->
                        </div>
                         <slot v-for="(megaMenu,megaIndex) in $store.state.footerMenus">
                            <div class="col-lg-3" :key="megaIndex">
                                <div class="pro-menu">
                                    <h4>{{megaMenu.title}}</h4>
                                    <ul class="nav flex-column">
                                        <slot v-for="(subMenu,subIndex) in megaMenu.sub_menu">
                                            <li class="nav-item" :key="subIndex">
                                                <a href="#" class="nav-link">{{subMenu.title}}</a>
                                            </li>
                                        </slot>
                                    </ul>
                                </div>
                            </div>
                        </slot>
                        <div class="col-lg-3">
                        <div class="pro-menu">
                            <h4>Connect With us</h4>
                            <ul class="nav flex-row">
                                 <li class="nav-item">
                                    <a :href="($store.state.appInfos!=null) ? $store.state.appInfos.facebook:''" target="_blank" class="nav-link">
                                        <img v-lazy="facebook" alt="Makeup World BD">
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a :href="($store.state.appInfos!=null) ? $store.state.appInfos.instagram:''" target="_blank" class="nav-link">
                                        <img v-lazy="instagram"   alt="Makeup World BD">
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a :href="($store.state.appInfos!=null) ? $store.state.appInfos.twitter:''" target="_blank" class="nav-link">
                                        <img  v-lazy="twitter"  alt="Makeup World BD">
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a :href="($store.state.appInfos!=null) ? $store.state.appInfos.youtube:''" target="_blank" class="nav-link">
                                        <img v-lazy="youtube"   alt="Makeup World BD">
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-10 text-right pt-3">
                        <p class="pay-text">Payment Methods: <img v-lazy="payment" alt="Makeup World BD"></p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /Product Details Modal -->
</template>

<script>
import moment from "moment";
export default {
    data() {
        return {
            bkash:require('../../assets/img/icon/bkash.png'),
            quantity:1,
            productId:this.$store.state.productId,
            sizeCode:0,
            colorCode:0,
            detailsImg: require('../../assets/img/products/products1.jpg'),
            hotLine:require('../../assets/img/icon/telephone.png'),
            payment:require('../../assets/img/payment.png'),
            facebook:require('../../assets/img/icon/facebook.png'),
            twitter:require('../../assets/img/icon/twitter.png'),
            instagram:require('../../assets/img/icon/instagram.png'),
            youtube:require('../../assets/img/icon/youtube.png'),
        }
    },
    mounted(){
       
    },
    methods:{
        productQuantityChange(value){
            if(value==-1 && parseInt(this.quantity)>1){
                this.quantity=parseInt(this.quantity)-1;
            }
            if(value==1){
                this.quantity=parseInt(this.quantity)+1;
            }

            this.addToCartSpecificProduct();
        },
        getSizeAndColorWisePrice(){
             this.productId=this.$store.state.productId;
            this.$http.get("product/details/size/color/wise",{params:{productId:this.productId,colorId:this.colorCode,sizeId:this.sizeCode}})
              .then(response=>{
                if(response.data.msgFlag==true)
                   this.$store.commit("storeProductDetails",response.data.dataInfos);
                // if(response.data.errMsgFlag==true)
                //   // this.$toaster.warning(response.data.errMsg);
              })
              .catch(error=>{console.dir(error)})
              .finally();

        },
         uniqueSizes(quantityInfos) {   
            let sizes=[];      
            quantityInfos.forEach(quantityInfo=>{
              if(!sizes.some(color => color.sizeId === quantityInfo.sizeId))
                sizes.push(quantityInfo);
            });
            return sizes;
        },
         uniqueColors(quantityInfos) {
           let colors=[];   
            quantityInfos.forEach(quantityInfo=>{
              if(!colors.some(color => color.colorId === quantityInfo.colorId))
                colors.push(quantityInfo);
            }); 
            return colors;
        },
        isDiscountTimeExpire(startAt,endAt){
            endAt = moment(endAt); //Date format

            startAt = moment(startAt); //Date format

            let today = moment();

            let isAfter = moment(endAt).isAfter(today);

            let isBefore = moment(startAt).isBefore(today);

            if(isAfter && isBefore)
                 return true;
            else
                 return false;
        },
        addToCartSpecificProduct(productId,hasSizeVarity,hasColorVarity){
           if(this.addToCartValidation(hasSizeVarity,hasColorVarity)){
                
                let cartInfos = this.$store.state.cartInfos;

                let formData=new FormData();

                let flag=false;

                this.productId=this.$store.state.productId;
          
                cartInfos.forEach(cartInfo => {
                if(
                  cartInfo.productId ==this.productId &&
                  cartInfo.isFreeProduct != true  &&
                  parseInt(cartInfo.colorId)==this.colorCode &&
                  parseInt(cartInfo.sizeId)==this.sizeCode
                ) {
                  let quantity = parseFloat(cartInfo.quantity) + 1;
                  formData.append("productId[]", this.productId);
                  formData.append("quantity[]", this.quantity);
                  formData.append("isFreeProduct[]", false);
                  formData.append("colorCode[]", this.colorCode);
                  formData.append("sizeCode[]", this.sizeCode);
                  formData.append("addedToCart[]", true);
                  flag = true;
                } 
                else 
                if(cartInfo.isFreeProduct != true ){

                  formData.append("productId[]", cartInfo.productId);
                  formData.append("quantity[]", cartInfo.quantity);
                  formData.append("isFreeProduct[]", cartInfo.isFreeProduct);
                  formData.append("colorCode[]", cartInfo.colorId);
                  formData.append("sizeCode[]", cartInfo.sizeId);
                  formData.append("addedToCart[]", false);
                }
              });
            
            if(!flag){
                formData.append("productId[]", this.productId);
                formData.append("quantity[]", this.quantity);
                formData.append("isFreeProduct[]", false);
                formData.append("colorCode[]",this.colorCode);
                formData.append("sizeCode[]", this.sizeCode);
                formData.append("addedToCart[]", true);

            }

                let config = {
                       headers: {
                          'Content-Type': 'multipart/form-data'
                      }   
                  }; 
            

               this.$http.post("product/add/to/cart",formData,config)
                .then(response=>{
                  if(response.data.msgFlag==true){
                    // console.dir(response.data);
                     this.$store.commit("storeCartInfos",response.data.cartInfos);
                     this.$store.commit("storePriceInfos",response.data);
                     this.$toaster.success(response.data.msg);
                  }
                  if(response.data.errMsgFlag==true){
                    this.$store.commit("storeCartInfos",response.data.cartInfos);
                    this.$store.commit("storePriceInfos",response.data);
                    this.$toaster.warning(response.data.errMsg);
                  }
                })
                .catch(error=>{console.dir(error)})
                .finally();
              }
          },
        addToCartValidation(hasSizeVarity,hasColorVarity){
          if(hasSizeVarity==1 && hasColorVarity==1){
            if(this.colorCode!=0 && this.sizeCode!=0){
                return true;
            }
            else{
                if(this.sizeCode==0)
                      this.$toaster.warning("Choose A Size First.");
                if(this.colorCode==0)
                    this.$toaster.warning("Choose A Color First.");

                return false;
            }
          }
          else{
            if(hasSizeVarity==1){
                if(this.sizeCode!=0){
                  return true;
                }
              else{
                  this.$toaster.warning("Choose A Size First.");
                  return false;
              }
            }
            else
              if(hasColorVarity==1){
                if(this.colorCode!=0){
                    return true;
                }
                else{
                    this.$toaster.warning("Choose A Color First.");
                    return false;
                }
            }
            else{
              this.colorCode=0;
              this.sizeCode=0;
              return true;
            }
          }
          // if(hasSizeColorVarity==1){
          //   if(this.colorCode!=0 && this.sizeCode!=0){
          //     return true;
          //   }
          //   else{
          //     if(this.colorCode==0)
          //       this.$toaster.warning("Choose A Color First.");
          //     if(this.sizeCode==0)
          //       this.$toaster.warning("Choose A Size First.");
          //     return false;
          //   }
          // }
          // else{
          //   return true;
          // }
        },

        getQuantityLabel(quantityType){
             let label="1 PCS";
              if(quantityType!=null){
                label=quantityType.smallQuantity+" "+quantityType.label;
              }
              return label;
        },
      
          getSpecificSellPrice(stockInfos){
             let sellPrice=0;
                stockInfos.forEach(stockInfo=>{
                   if(stockInfo.frontend_stock_infos!=null){
                      stockInfo.frontend_stock_infos.forEach(stock=>{
                          if(this.colorCode==stock.colorId && this.sizeCode==stock.sizeId){
                            sellPrice=parseInt(stock.sellPrice);
                            // this.colorCode=stockInfo.stock_info.colorId;
                            // this.sizeCode=stockInfo.stock_info.sizeId;
                          }
                      });
                   }
                });
                if(sellPrice==0){
                    stockInfos.forEach(stockInfo=>{
                     if(stockInfo.frontend_stock_infos!=null){
                      stockInfo.frontend_stock_infos.forEach(stock=>{
                            sellPrice=parseInt(stock.sellPrice);
                            // this.colorCode=stockInfo.stock_info.colorId;
                            // this.sizeCode=stockInfo.stock_info.sizeId;
                        });
                     }
                  });
                }
               return parseInt(sellPrice);
          },
          
          getProductQuantity(stockInfos){
               
               let quantity=0;
                stockInfos.forEach(stockInfo=>{
                    quantity=quantity+stockInfo.quantity;
                });
                
                return quantity;
          },

    }
}
</script>

<style>

</style>